<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          <span>Average report by days in week:</span>
        </h3>
      </div>
      <div class="card-body pt-0">
        <VueApexCharts
          v-if="isReady"
          :options="options"
          :series="series"
        ></VueApexCharts>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import getDay from 'date-fns/getDay'
import getWeek from 'date-fns/getWeek'
import { dateFilter } from 'vue-date-fns'
import format from 'date-fns/format'
import { mapGetters } from 'vuex'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import isMonday from 'date-fns/isMonday'
import isTuesday from 'date-fns/isTuesday'
import isWednesday from 'date-fns/isWednesday'
import isThursday from 'date-fns/isThursday'
import isFriday from 'date-fns/isFriday'
import isSaturday from 'date-fns/isSaturday'
import isSunday from 'date-fns/isSunday'

export default {
  name: 'WeekDaysTrafficNew',
  filters: {
    date: dateFilter,
  },
  components: {
    VueApexCharts,
  },
  props: {
    transactions: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      parsedTransactions: [],
      coinTransactions: [],
      smsTransactions: [],
      loyaltyTransactions: [],
      days: [],
      day1: {
        coin: { name: 'day1', total: 0 },
        sms: { name: 'day1', total: 0 },
        loyalty: { name: 'day1', total: 0 },
      },
      day2: {
        coin: { name: 'day2', total: 0 },
        sms: { name: 'day2', total: 0 },
        loyalty: { name: 'day2', total: 0 },
      },
      day3: {
        coin: { name: 'day3', total: 0 },
        sms: { name: 'day3', total: 0 },
        loyalty: { name: 'day3', total: 0 },
      },
      day4: {
        coin: { name: 'day4', total: 0 },
        sms: { name: 'day4', total: 0 },
        loyalty: { name: 'day4', total: 0 },
      },
      day5: {
        coin: { name: 'day5', total: 0 },
        sms: { name: 'day5', total: 0 },
        loyalty: { name: 'day5', total: 0 },
      },
      day6: {
        coin: { name: 'day6', total: 0 },
        sms: { name: 'day6', total: 0 },
        loyalty: { name: 'day6', total: 0 },
      },
      day0: {
        coin: { name: 'day0', total: 0 },
        sms: { name: 'day0', total: 0 },
        loyalty: { name: 'day0', total: 0 },
      },
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        xaxis: {
          categories: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          labels: {
            show: true,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        grid: {
          show: true,
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.transactions.forEach((transaction) => {
      this.parsedTransactions.push({
        day: new Date(transaction.createdAt),
        week: getWeek(new Date(transaction.createdAt)),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.coinTransactions = this.parsedTransactions.filter(
      (transaction) =>
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci',
    )

    this.smsTransactions = this.parsedTransactions.filter(
      (transaction) => transaction.paymentType === 'pay_sms',
    )

    this.loyaltyTransactions = this.parsedTransactions.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard',
    )

    this.coinTransactions.forEach((transaction) => {
      const thisDay = getDay(new Date(transaction.day))

      if (thisDay === 1) this.day1.coin.total += parseFloat(transaction.amount)
      if (thisDay === 2) this.day2.coin.total += parseFloat(transaction.amount)
      if (thisDay === 3) this.day3.coin.total += parseFloat(transaction.amount)
      if (thisDay === 4) this.day4.coin.total += parseFloat(transaction.amount)
      if (thisDay === 5) this.day5.coin.total += parseFloat(transaction.amount)
      if (thisDay === 6) this.day6.coin.total += parseFloat(transaction.amount)
      if (thisDay === 0) this.day0.coin.total += parseFloat(transaction.amount)
    })

    this.smsTransactions.forEach((transaction) => {
      const thisDay = getDay(new Date(transaction.day))

      if (thisDay === 1) this.day1.sms.total += parseFloat(transaction.amount)
      if (thisDay === 2) this.day2.sms.total += parseFloat(transaction.amount)
      if (thisDay === 3) this.day3.sms.total += parseFloat(transaction.amount)
      if (thisDay === 4) this.day4.sms.total += parseFloat(transaction.amount)
      if (thisDay === 5) this.day5.sms.total += parseFloat(transaction.amount)
      if (thisDay === 6) this.day6.sms.total += parseFloat(transaction.amount)
      if (thisDay === 0) this.day0.sms.total += parseFloat(transaction.amount)
    })

    this.loyaltyTransactions.forEach((transaction) => {
      const thisDay = getDay(new Date(transaction.day))

      if (thisDay === 1)
        this.day1.loyalty.total += parseFloat(transaction.amount)
      if (thisDay === 2)
        this.day2.loyalty.total += parseFloat(transaction.amount)
      if (thisDay === 3)
        this.day3.loyalty.total += parseFloat(transaction.amount)
      if (thisDay === 4)
        this.day4.loyalty.total += parseFloat(transaction.amount)
      if (thisDay === 5)
        this.day5.loyalty.total += parseFloat(transaction.amount)
      if (thisDay === 6)
        this.day6.loyalty.total += parseFloat(transaction.amount)
      if (thisDay === 0)
        this.day0.loyalty.total += parseFloat(transaction.amount)
    })

    let monday = 0
    let tuesday = 0
    let wednesday = 0
    let thursday = 0
    let friday = 0
    let saturday = 0
    let sunday = 0

    eachDayOfInterval(this.range).forEach((day) => {
      if (isMonday(day)) monday++
      if (isTuesday(day)) tuesday++
      if (isWednesday(day)) wednesday++
      if (isThursday(day)) thursday++
      if (isFriday(day)) friday++
      if (isSaturday(day)) saturday++
      if (isSunday(day)) sunday++
    })

    this.series[0].data.push(this.day1.coin.total / monday) // pon
    this.series[0].data.push(this.day2.coin.total / tuesday)
    this.series[0].data.push(this.day3.coin.total / wednesday)
    this.series[0].data.push(this.day4.coin.total / thursday)
    this.series[0].data.push(this.day5.coin.total / friday)
    this.series[0].data.push(this.day6.coin.total / saturday)
    this.series[0].data.push(this.day0.coin.total / sunday) // ned

    //
    this.series[1].data.push(this.day1.sms.total / monday) // pon
    this.series[1].data.push(this.day2.sms.total / tuesday)
    this.series[1].data.push(this.day3.sms.total / wednesday)
    this.series[1].data.push(this.day4.sms.total / thursday)
    this.series[1].data.push(this.day5.sms.total / friday)
    this.series[1].data.push(this.day6.sms.total / saturday)
    this.series[1].data.push(this.day0.sms.total / sunday) // ned

    //
    this.series[2].data.push(this.day1.loyalty.total / monday) // pon
    this.series[2].data.push(this.day2.loyalty.total / tuesday)
    this.series[2].data.push(this.day3.loyalty.total / wednesday)
    this.series[2].data.push(this.day4.loyalty.total / thursday)
    this.series[2].data.push(this.day5.loyalty.total / friday)
    this.series[2].data.push(this.day6.loyalty.total / saturday)
    this.series[2].data.push(this.day0.loyalty.total / sunday) // ned

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    exportAsExcel() {
      const after = format(this.range.start, 'dd_MM_yyyy')
      const before = format(this.range.end, 'dd_MM_yyyy')
      const fileFormat = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'report_by_device' + after + '_' + before
      this.$refs.location.exportTable(fileFormat, exportSelectedOnly, filename)
    },
  },
}
</script>
