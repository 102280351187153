<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Report by days in range:
        </h3>
      </div>
      <div class="card-body mx-0 px-0">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { dateFilter } from 'vue-date-fns'
import format from 'date-fns/format'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import { mapGetters } from 'vuex'

export default {
  name: 'DaysTrafficNew',
  filters: {
    date: dateFilter,
  },
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    type: {
      type: String,
    },
    transactions: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      coinTransactions: [],
      smsTransactions: [],
      loyaltyTransactions: [],
      days: [],
      options: {
        tooltip: {
          enabled: true,
          x: {
            show: true,
          },
          y: {
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
            title: {
              formatter(seriesName) {
                return seriesName
              },
            },
          },
        },
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              orientation: 'horizontal',
              position: 'center',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 0,
          formatter(val) {
            if (val !== null) {
              return val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return val
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule', 'currentUserCompanyOib']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming') {
      this.options.legend.show = true
    }

    this.days = eachDayOfInterval({
      start: new Date(this.range.start),
      end: new Date(this.range.end),
    })

    if (this.days.length >= 30) {
      this.options.plotOptions.bar.horizontal = true
      this.options.plotOptions.bar.dataLabels.orientation = 'horizontal'
      this.options.plotOptions.bar.dataLabels.position = 'bottom'
      this.options.dataLabels.offsetX = 5
      this.options.dataLabels.offsetY = 0

      this.days.forEach((day) => {
        let dayCoinAmount = 0
        let daySmsAmount = 0
        let dayLoyaltyAmount = 0

        this.transactions.forEach((transaction) => {
          if (
            transaction.createdAt.slice(0, 10) === format(day, 'yyyy-MM-dd')
          ) {
            if (
              transaction.paymentType === 'pay_coin' ||
              transaction.paymentType === 'pay_cctalk' ||
              transaction.paymentType === 'pay_mdb' ||
              transaction.paymentType === 'pay_sci'
            )
              dayCoinAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_sms')
              daySmsAmount = daySmsAmount + parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_rfcard')
              dayLoyaltyAmount =
                dayLoyaltyAmount + parseFloat(transaction.amount)
          }
        })

        this.series[0].data.push(dayCoinAmount)
        this.series[1].data.push(daySmsAmount)
        this.series[2].data.push(dayLoyaltyAmount)
        this.options.xaxis.categories.push(format(day, 'dd.MM.yyyy'))
      })
    } else if (this.days.length > 14 && this.days.length < 29) {
      this.options.plotOptions.bar.horizontal = false
      this.options.plotOptions.bar.dataLabels.orientation = 'vertical'
      this.options.plotOptions.bar.dataLabels.position = 'center'
      this.options.dataLabels.offsetX = 0
      this.options.dataLabels.offsetY = 0
      this.days.forEach((day) => {
        let dayCoinAmount = 0
        let daySmsAmount = 0
        let dayLoyaltyAmount = 0

        this.transactions.forEach((transaction) => {
          if (
            transaction.createdAt.slice(0, 10) === format(day, 'yyyy-MM-dd')
          ) {
            if (
              transaction.paymentType === 'pay_coin' ||
              transaction.paymentType === 'pay_cctalk' ||
              transaction.paymentType === 'pay_mdb' ||
              transaction.paymentType === 'pay_sci'
            )
              dayCoinAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_sms')
              daySmsAmount = daySmsAmount + parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_rfcard')
              dayLoyaltyAmount =
                dayLoyaltyAmount + parseFloat(transaction.amount)
          }
        })

        this.series[0].data.push(dayCoinAmount)
        this.series[1].data.push(daySmsAmount)
        this.series[2].data.push(dayLoyaltyAmount)
        this.options.xaxis.categories.push(format(day, 'dd.MM.yyyy'))
      })
    } else {
      this.days.forEach((day) => {
        let dayCoinAmount = 0
        let daySmsAmount = 0
        let dayLoyaltyAmount = 0

        this.transactions.forEach((transaction) => {
          if (
            transaction.createdAt.slice(0, 10) === format(day, 'yyyy-MM-dd')
          ) {
            if (
              transaction.paymentType === 'pay_coin' ||
              transaction.paymentType === 'pay_cctalk' ||
              transaction.paymentType === 'pay_mdb' ||
              transaction.paymentType === 'pay_sci'
            )
              dayCoinAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_sms')
              daySmsAmount = daySmsAmount + parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_rfcard')
              dayLoyaltyAmount =
                dayLoyaltyAmount + parseFloat(transaction.amount)
          }
        })

        this.series[0].data.push(dayCoinAmount)
        this.series[1].data.push(daySmsAmount)
        this.series[2].data.push(dayLoyaltyAmount)
        this.options.xaxis.categories.push(format(day, 'dd.MM.yyyy'))
      })
    }

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
}
</script>
