<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">Weeks</h3>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body mx-0 px-0">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
// import { getWeeksMeta } from '@/core/helpers/helpers'
// import parse from 'date-fns/parse'
import getWeek from 'date-fns/getWeek'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'WeekTrafficNew',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    transactions: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      parsedTransactions: [],
      weeks: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 0,
          formatter(val) {
            if (val !== null) {
              return val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins, Helvetica, "sans-serif"',
              fontWeight: 400,
              cssClass: 'week-label',
            },
            formatter(value) {
              return value
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              if (val) {
                return (
                  val
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
                )
              }
              return val
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming') {
      this.options.legend.show = true
    }

    // this.transactions.reverse()

    this.transactions.forEach((transaction) => {
      this.parsedTransactions.push({
        week: getWeek(new Date(transaction.createdAt), {
          weekStartsOn: 1,
        }),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.coinTransactions = this.parsedTransactions.filter(
      (transaction) =>
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci',
    )

    this.parsedTransactions.forEach((transaction) => {
      if (this.options.xaxis.categories.indexOf(transaction.week) === -1) {
        this.options.xaxis.categories.push(transaction.week)
        this.weeks.push(transaction.week)
      }
    })

    this.options.xaxis.categories.forEach((week) => {
      let weekCoinAmount = 0
      let weekSmsAmount = 0
      let weekLoyaltyAmount = 0

      this.parsedTransactions.forEach((transaction) => {
        if (transaction.week === week) {
          if (
            transaction.paymentType === 'pay_coin' ||
            transaction.paymentType === 'pay_cctalk' ||
            transaction.paymentType === 'pay_mdb' ||
            transaction.paymentType === 'pay_sci'
          ) {
            weekCoinAmount += parseFloat(transaction.amount)
          }
          if (transaction.paymentType === 'pay_sms') {
            weekSmsAmount += parseFloat(transaction.amount)
          }
          if (transaction.paymentType === 'pay_rfcard') {
            weekLoyaltyAmount += parseFloat(transaction.amount)
          }
        }
      })

      this.series[0].data.push(weekCoinAmount)
      this.series[1].data.push(weekSmsAmount)
      this.series[2].data.push(weekLoyaltyAmount)
    })

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
}
</script>
