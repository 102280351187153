<template>
  <div class="table-responsive mb-0 pb-0">
    <table class="table table-head-custom table-vertical-center table-head-bg">
      <thead>
        <tr class="text-uppercase">
          <th class="pl-6">
            <span @click="orderByDeviceName" class="cursor-pointer">
              <span
                class="pt-2"
                :class="{ 'text-primary': deviceNameOrder.isActive }"
              >
                {{ $t('COMPANIES.DEVICE_NAME') }}
              </span>
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': deviceNameOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="deviceNameOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th class="text-right">{{ $t('GENERAL.LOCATION') }}</th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            {{ $t('GENERAL.LESSOR') }}
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            Netto Coin
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right cursor-pointer"
          >
            <span @click="orderByCoin" class="cursor-pointer">
              <span
                class="pt-2"
                :class="{ 'text-primary': coinOrder.isActive }"
              >
                <span v-b-tooltip.hover="'Brutto Coin'">{{
                  $t('GENERAL.COIN')
                }}</span></span
              >
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': coinOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="coinOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            Netto SMS
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right cursor-pointer"
          >
            <span @click="orderBySms" class="cursor-pointer">
              <span class="pt-2" :class="{ 'text-primary': smsOrder.isActive }">
                <span v-b-tooltip.hover="'Brutto SMS'">SMS</span></span
              >
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': smsOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="smsOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            Netto CS
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            Pdv
          </th>
          <th class="text-right cursor-pointer">
            <span @click="orderByCs" class="cursor-pointer">
              <span class="pt-2" :class="{ 'text-primary': cSOrder.isActive }">
                <span
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  v-b-tooltip.hover="'Brutto Coin + SMS'"
                  >CS</span
                >
                <span v-else v-b-tooltip.hover="'Brutto Total'">{{
                  $t('GENERAL.TOTAL')
                }}</span>
              </span>
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': cSOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="cSOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            <span @click="orderByLoyalty" class="cursor-pointer">
              <span
                class="pt-2"
                :class="{ 'text-primary': loyaltyOrder.isActive }"
              >
                <span>Loyalty</span></span
              >
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': loyaltyOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="loyaltyOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right cursor-pointer pr-6"
          >
            <span @click="orderByTotal" class="cursor-pointer">
              <span
                class="pt-2"
                :class="{ 'text-primary': totalOrder.isActive }"
              >
                <span v-b-tooltip.hover="'Coin + SMS + Loyalty'">{{
                  $t('GENERAL.TOTAL')
                }}</span></span
              >
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': totalOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="totalOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(device, key) in devices" :key="key">
          <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
            <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{
              device.name
            }}</span>
            <div>
              <span
                class="label label-sm label-light-primary label-inline mr-2"
                >{{ device.tags }}</span
              >
            </div>
            <div class="mt-1">{{ device.imei }}</div>
          </td>
          <td class="text-right" :class="{ 'border-top-0': key === 0 }">
            {{ device.location }}
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
            :class="{ 'border-top-0': key === 0 }"
          >
            {{ device.lessor }}
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-right"
          >
            <span v-if="device.netCoin !== 0"
              >{{ device.netCoin | currencyFormat }}
            </span>
            <span v-else>0,00€</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-right"
          >
            <span v-if="device.coin !== 0"
              >{{ device.coin | currencyFormat }}
            </span>
            <span v-else>0,00€</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-right"
          >
            <span v-if="device.netSms !== 0"
              >{{ device.netSms | currencyFormat }}
            </span>
            <span v-else>0,00€</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-right"
          >
            <span v-if="device.sms !== 0">{{
              device.sms | currencyFormat
            }}</span>
            <span v-else>0,00€</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
            :class="{ 'border-top-0': key === 0 }"
          >
            <span v-if="device.net !== 0">{{
              device.net | currencyFormat
            }}</span>
            <span v-else>0,00€</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
            :class="{ 'border-top-0': key === 0 }"
          >
            <span v-if="device.pdv !== 0">{{
              device.pdv | currencyFormat
            }}</span>
            <span v-else>0,00€</span>
          </td>
          <td
            class="text-dark-75 font-weight-bolder text-right"
            :class="{ 'border-top-0': key === 0 }"
          >
            <span v-if="device.amount !== 0">{{
              device.amount | currencyFormat
            }}</span>
            <span v-else>0,00€</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-dark-75 font-weight-bolder text-right"
          >
            <span v-if="device.loyalty !== 0"
              >{{ device.loyalty | currencyFormat }}
            </span>
            <span v-else>0,00€</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-dark-75 font-weight-bolder font-italic text-right pr-6"
          >
            <span v-if="device.total !== 0"
              >{{ device.total | currencyFormat }}
            </span>
            <span v-else>0,00€</span>
          </td>
        </tr>

        <tr>
          <td class="pt-10 pl-6 font-size-lg font-weight-bolder text-uppercase">
            {{ $t('GENERAL.TOTAL') }}: {{ devices.length }}
            {{ $t('MAIN_MENU.DEVICES') }}
          </td>
          <td></td>
          <td v-if="currentUserCompanyModule[0] === 'gaming'"></td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            <span v-if="generateReportNetCoin !== 0"
              >{{ generateReportNetCoin | currencyFormat }}
            </span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            <span v-if="generateReportCoin !== 0"
              >{{ generateReportCoin | currencyFormat }}
            </span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            <span v-if="generateReportNetSms !== 0">{{
              generateReportNetSms | currencyFormat
            }}</span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            <span v-if="generateReportSms !== 0">{{
              generateReportSms | currencyFormat
            }}</span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            {{ generateReportNet | currencyFormat }}
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            {{ generateReportPdv | currencyFormat }}
          </td>
          <td
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
          >
            {{ generateReportAmount | currencyFormat }}
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
          >
            <span v-if="generateReportLoyalty !== 0">{{
              generateReportLoyalty | currencyFormat
            }}</span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
          >
            <span v-if="generateReportTotal !== 0">{{
              generateReportTotal | currencyFormat
            }}</span>
            <span v-else>0</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DevicesReportTableNew',
  props: {
    devices: { type: Array },
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib', 'currentUserCompanyModule']),
  },
  data() {
    return {
      generateReportNetCoin: 0,
      generateReportCoin: 0,
      generateReportNetSms: 0,
      generateReportSms: 0,
      generateReportNet: 0,
      generateReportPdv: 0,
      generateReportAmount: 0,
      generateReportLoyalty: 0,
      generateReportTotal: 0,
      deviceNameOrder: { query: 'asc', isActive: false },
      coinOrder: { query: 'asc', isActive: false },
      smsOrder: { query: 'asc', isActive: false },
      cSOrder: { query: 'asc', isActive: false },
      loyaltyOrder: { query: 'asc', isActive: false },
      totalOrder: { query: 'asc', isActive: false },
    }
  },
  mounted() {
    this.devices.forEach((device) => {
      this.generateReportNetCoin = this.generateReportNetCoin + device.netCoin
      this.generateReportCoin = this.generateReportCoin + device.coin
      this.generateReportNetSms = this.generateReportNetSms + device.netSms
      this.generateReportSms = this.generateReportSms + device.sms
      this.generateReportNet = this.generateReportNet + device.net
      this.generateReportPdv = this.generateReportPdv + device.pdv
      this.generateReportAmount = this.generateReportAmount + device.amount
      this.generateReportLoyalty = this.generateReportLoyalty + device.loyalty
      this.generateReportTotal = this.generateReportTotal + device.total
    })
    this.orderByDeviceName()
  },
  methods: {
    orderByDeviceName() {
      this.clearActiveFilters()
      this.deviceNameOrder.isActive = true

      if (this.deviceNameOrder.query === 'asc') {
        this.deviceNameOrder.query = 'desc'
        this.devices.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      } else {
        this.deviceNameOrder.query = 'asc'
        this.devices.sort((a, b) => {
          if (a.name > b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      }
    },
    orderByCoin() {
      this.clearActiveFilters()
      this.coinOrder.isActive = true

      if (this.coinOrder.query === 'asc') {
        this.coinOrder.query = 'desc'
        this.devices.sort((a, b) => a.coin - b.coin)
      } else {
        this.coinOrder.query = 'asc'
        this.devices.sort((a, b) => b.coin - a.coin)
      }
    },
    orderBySms() {
      this.clearActiveFilters()
      this.smsOrder.isActive = true

      if (this.smsOrder.query === 'asc') {
        this.smsOrder.query = 'desc'
        this.devices.sort((a, b) => a.sms - b.sms)
      } else {
        this.smsOrder.query = 'asc'
        this.devices.sort((a, b) => b.sms - a.sms)
      }
    },
    orderByCs() {
      this.clearActiveFilters()
      this.cSOrder.isActive = true

      if (this.cSOrder.query === 'asc') {
        this.cSOrder.query = 'desc'
        this.devices.sort((a, b) => a.amount - b.amount)
      } else {
        this.cSOrder.query = 'asc'
        this.devices.sort((a, b) => b.amount - a.amount)
      }
    },
    orderByLoyalty() {
      this.clearActiveFilters()
      this.loyaltyOrder.isActive = true

      if (this.loyaltyOrder.query === 'asc') {
        this.loyaltyOrder.query = 'desc'
        this.devices.sort((a, b) => a.loyalty - b.loyalty)
      } else {
        this.loyaltyOrder.query = 'asc'
        this.devices.sort((a, b) => b.loyalty - a.loyalty)
      }
    },
    orderByTotal() {
      this.clearActiveFilters()
      this.totalOrder.isActive = true

      if (this.totalOrder.query === 'asc') {
        this.totalOrder.query = 'desc'
        this.devices.sort((a, b) => a.total - b.total)
      } else {
        this.totalOrder.query = 'asc'
        this.devices.sort((a, b) => b.total - a.total)
      }
    },
    clearActiveFilters() {
      this.deviceNameOrder.isActive = false
      this.coinOrder.isActive = false
      this.smsOrder.isActive = false
      this.cSOrder.isActive = false
      this.loyaltyOrder.isActive = false
      this.totalOrder.isActive = false
    },
  },
}
</script>
