<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Devices with traffic in range:
        </h3>
        <div class="card-toolbar">
          <a
            :href="downloadExcelUrl"
            class="btn btn-light-primary font-weight-bold px-10"
            >Export .xlsx</a
          >
        </div>
      </div>
      <div class="card-body pt-0">
        <DevicesReportTableNew
          v-if="isGenerated"
          :devices="devices"
        ></DevicesReportTableNew>
      </div>
    </div>

    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Devices without traffic same range
        </h3>
      </div>
      <div class="card-body pt-0">
        <div class="table-responsive mb-0 pb-0">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-uppercase">
                <th class="pl-6">
                  <span>{{ $t('COMPANIES.DEVICE_NAME') }}</span>
                </th>
                <th class="text-right">
                  <span>{{ $t('GENERAL.LOCATION') }}</span>
                </th>
                <th
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  class="text-right"
                >
                  <span>{{ $t('GENERAL.LESSOR') }}</span>
                </th>
                <th
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  class="text-right"
                >
                  <span>Last Pay Sms</span>
                </th>
                <th
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  class="text-right"
                >
                  <span>Last Pay Coin</span>
                </th>
                <th
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  class="text-right pr-6"
                >
                  <span>Last Pay Loyalty</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(device, key) in noTrafficDevices" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <span
                    class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                    >{{ device.name }}</span
                  >
                  <div>
                    <span
                      v-for="(tag, key) in device.tags"
                      :key="key"
                      class="label label-sm label-light-primary label-inline mr-2"
                      >{{ tag.name }}</span
                    >
                  </div>
                  <div class="mt-1">{{ device.logger.imei }}</div>
                </td>
                <td class="text-right" :class="{ 'border-top-0': key === 0 }">
                  {{ device.location.title }}
                </td>
                <td
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  class="text-right"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  {{ device.location?.lessor.name }}
                </td>
                <td
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  class="text-right"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <span v-if="device.eventMeta.lastPaySms">
                    {{ device.eventMeta.lastPaySms | formatDateTime }}
                  </span>
                  <span v-else>---</span>
                </td>
                <td class="text-right" :class="{ 'border-top-0': key === 0 }">
                  <span v-if="device.eventMeta.lastPayCoin">
                    {{ device.eventMeta.lastPayCoin | formatDateTime }}
                  </span>
                  <span v-else>---</span>
                </td>
                <td
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  class="text-right pr-6"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <span v-if="device.eventMeta.lastPayRfCard">
                    {{ device.eventMeta.lastPayRfCard | formatDateTime }}
                  </span>
                  <span v-else>---</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DevicesReportTableNew from '@/view/content/components/devices-report-table-new/DevicesReportTableNew'

export default {
  name: 'DevicesTrafficNew',
  props: {
    devices: {
      type: Array,
    },
    noTrafficDevices: {
      type: Array,
    },
    downloadExcelUrl: {
      type: String,
    },
    range: {
      type: Object,
    },
  },
  components: {
    DevicesReportTableNew,
  },
  data() {
    return {
      isGenerated: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib', 'currentUserCompanyModule']),
  },
  mounted() {
    setTimeout(() => {
      this.isGenerated = true
    }, 500)
  },
  methods: {},
}
</script>
