<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">Month</h3>
      </div>
      <div class="card-body">
        <VueApexCharts
          v-if="isReady"
          :options="options"
          :series="series"
        ></VueApexCharts>
      </div>
    </div>
  </div>
</template>

<script>
import getMonth from 'date-fns/getMonth'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'MonthTrafficNew',
  components: {
    VueApexCharts,
  },
  props: {
    transactions: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      parsedTransactions: [],
      coinTransactions: [],
      smsTransactions: [],
      loyaltyTransactions: [],
      totalMonthAmount0: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount1: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount2: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount3: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount4: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount5: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount6: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount7: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount8: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount9: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount10: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      totalMonthAmount11: {
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
      },
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            if (val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              if (val) {
                return (
                  val
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
                )
              }
              return val
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.transactions.forEach((transaction) => {
      this.parsedTransactions.push({
        month: getMonth(new Date(transaction.createdAt)),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.coinTransactions = this.parsedTransactions.filter(
      (transaction) =>
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci',
    )

    this.smsTransactions = this.parsedTransactions.filter(
      (transaction) => transaction.paymentType === 'pay_sms',
    )

    this.loyaltyTransactions = this.parsedTransactions.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard',
    )

    // coin
    this.coinTransactions.forEach((transaction) => {
      if (transaction.month === 0) {
        this.totalMonthAmount0.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('January') === false)
          this.options.xaxis.categories.push('January')
      }
      if (transaction.month === 1) {
        this.totalMonthAmount1.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('February') === false)
          this.options.xaxis.categories.push('February')
      }
      if (transaction.month === 2) {
        this.totalMonthAmount2.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('March') === false)
          this.options.xaxis.categories.push('March')
      }
      if (transaction.month === 3) {
        this.totalMonthAmount3.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('April') === false)
          this.options.xaxis.categories.push('April')
      }
      if (transaction.month === 4) {
        this.totalMonthAmount4.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('May') === false)
          this.options.xaxis.categories.push('May')
      }
      if (transaction.month === 5) {
        this.totalMonthAmount5.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('June') === false)
          this.options.xaxis.categories.push('June')
      }
      if (transaction.month === 6) {
        this.totalMonthAmount6.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('July') === false)
          this.options.xaxis.categories.push('July')
      }
      if (transaction.month === 7) {
        this.totalMonthAmount7.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('August') === false)
          this.options.xaxis.categories.push('August')
      }
      if (transaction.month === 8) {
        this.totalMonthAmount8.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('September') === false)
          this.options.xaxis.categories.push('September')
      }
      if (transaction.month === 9) {
        this.totalMonthAmount9.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('October') === false)
          this.options.xaxis.categories.push('October')
      }
      if (transaction.month === 10) {
        this.totalMonthAmount10.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('November') === false)
          this.options.xaxis.categories.push('November')
      }
      if (transaction.month === 11) {
        this.totalMonthAmount11.coin.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('December') === false)
          this.options.xaxis.categories.push('December')
      }
    })

    if (this.totalMonthAmount0.coin.total)
      this.series[0].data.push(this.totalMonthAmount0.coin.total)
    if (this.totalMonthAmount1.coin.total)
      this.series[0].data.push(this.totalMonthAmount1.coin.total)
    if (this.totalMonthAmount2.coin.total)
      this.series[0].data.push(this.totalMonthAmount2.coin.total)
    if (this.totalMonthAmount3.coin.total)
      this.series[0].data.push(this.totalMonthAmount3.coin.total)
    if (this.totalMonthAmount4.coin.total)
      this.series[0].data.push(this.totalMonthAmount4.coin.total)
    if (this.totalMonthAmount5.coin.total)
      this.series[0].data.push(this.totalMonthAmount5.coin.total)
    if (this.totalMonthAmount6.coin.total)
      this.series[0].data.push(this.totalMonthAmount6.coin.total)
    if (this.totalMonthAmount7.coin.total)
      this.series[0].data.push(this.totalMonthAmount7.coin.total)
    if (this.totalMonthAmount8.coin.total)
      this.series[0].data.push(this.totalMonthAmount8.coin.total)
    if (this.totalMonthAmount9.coin.total)
      this.series[0].data.push(this.totalMonthAmount9.coin.total)
    if (this.totalMonthAmount10.coin.total)
      this.series[0].data.push(this.totalMonthAmount10.coin.total)
    if (this.totalMonthAmount11.coin.total)
      this.series[0].data.push(this.totalMonthAmount11.coin.total)

    // sms
    this.smsTransactions.forEach((transaction) => {
      if (transaction.month === 0) {
        this.totalMonthAmount0.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('January') === false)
          this.options.xaxis.categories.push('January')
      }
      if (transaction.month === 1) {
        this.totalMonthAmount1.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('February') === false)
          this.options.xaxis.categories.push('February')
      }
      if (transaction.month === 2) {
        this.totalMonthAmount2.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('March') === false)
          this.options.xaxis.categories.push('March')
      }
      if (transaction.month === 3) {
        this.totalMonthAmount3.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('April') === false)
          this.options.xaxis.categories.push('April')
      }
      if (transaction.month === 4) {
        this.totalMonthAmount4.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('May') === false)
          this.options.xaxis.categories.push('May')
      }
      if (transaction.month === 5) {
        this.totalMonthAmount5.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('June') === false)
          this.options.xaxis.categories.push('June')
      }
      if (transaction.month === 6) {
        this.totalMonthAmount6.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('July') === false)
          this.options.xaxis.categories.push('July')
      }
      if (transaction.month === 7) {
        this.totalMonthAmount7.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('August') === false)
          this.options.xaxis.categories.push('August')
      }
      if (transaction.month === 8) {
        this.totalMonthAmount8.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('September') === false)
          this.options.xaxis.categories.push('September')
      }
      if (transaction.month === 9) {
        this.totalMonthAmount9.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('October') === false)
          this.options.xaxis.categories.push('October')
      }
      if (transaction.month === 10) {
        this.totalMonthAmount10.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('November') === false)
          this.options.xaxis.categories.push('November')
      }
      if (transaction.month === 11) {
        this.totalMonthAmount11.sms.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('December') === false)
          this.options.xaxis.categories.push('December')
      }
    })

    if (this.totalMonthAmount0.sms.total)
      this.series[1].data.push(this.totalMonthAmount0.sms.total)
    if (this.totalMonthAmount1.sms.total)
      this.series[1].data.push(this.totalMonthAmount1.sms.total)
    if (this.totalMonthAmount2.sms.total)
      this.series[1].data.push(this.totalMonthAmount2.sms.total)
    if (this.totalMonthAmount3.sms.total)
      this.series[1].data.push(this.totalMonthAmount3.sms.total)
    if (this.totalMonthAmount4.sms.total)
      this.series[1].data.push(this.totalMonthAmount4.sms.total)
    if (this.totalMonthAmount5.sms.total)
      this.series[1].data.push(this.totalMonthAmount5.sms.total)
    if (this.totalMonthAmount6.sms.total)
      this.series[1].data.push(this.totalMonthAmount6.sms.total)
    if (this.totalMonthAmount7.sms.total)
      this.series[1].data.push(this.totalMonthAmount7.sms.total)
    if (this.totalMonthAmount8.sms.total)
      this.series[1].data.push(this.totalMonthAmount8.sms.total)
    if (this.totalMonthAmount9.sms.total)
      this.series[1].data.push(this.totalMonthAmount9.sms.total)
    if (this.totalMonthAmount10.sms.total)
      this.series[1].data.push(this.totalMonthAmount10.sms.total)
    if (this.totalMonthAmount11.sms.total)
      this.series[1].data.push(this.totalMonthAmount11.sms.total)

    // loyalty
    this.loyaltyTransactions.forEach((transaction) => {
      if (transaction.month === 0) {
        this.totalMonthAmount0.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('January') === false)
          this.options.xaxis.categories.push('January')
      }
      if (transaction.month === 1) {
        this.totalMonthAmount1.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('February') === false)
          this.options.xaxis.categories.push('February')
      }
      if (transaction.month === 2) {
        this.totalMonthAmount2.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('March') === false)
          this.options.xaxis.categories.push('March')
      }
      if (transaction.month === 3) {
        this.totalMonthAmount3.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('April') === false)
          this.options.xaxis.categories.push('April')
      }
      if (transaction.month === 4) {
        this.totalMonthAmount4.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('May') === false)
          this.options.xaxis.categories.push('May')
      }
      if (transaction.month === 5) {
        this.totalMonthAmount5.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('June') === false)
          this.options.xaxis.categories.push('June')
      }
      if (transaction.month === 6) {
        this.totalMonthAmount6.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('July') === false)
          this.options.xaxis.categories.push('July')
      }
      if (transaction.month === 7) {
        this.totalMonthAmount7.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('August') === false)
          this.options.xaxis.categories.push('August')
      }
      if (transaction.month === 8) {
        this.totalMonthAmount8.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('September') === false)
          this.options.xaxis.categories.push('September')
      }
      if (transaction.month === 9) {
        this.totalMonthAmount9.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('October') === false)
          this.options.xaxis.categories.push('October')
      }
      if (transaction.month === 10) {
        this.totalMonthAmount10.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('November') === false)
          this.options.xaxis.categories.push('November')
      }
      if (transaction.month === 11) {
        this.totalMonthAmount11.loyalty.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('December') === false)
          this.options.xaxis.categories.push('December')
      }
    })

    if (this.totalMonthAmount0.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount0.loyalty.total)
    if (this.totalMonthAmount1.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount1.loyalty.total)
    if (this.totalMonthAmount2.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount2.loyalty.total)
    if (this.totalMonthAmount3.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount3.loyalty.total)
    if (this.totalMonthAmount4.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount4.loyalty.total)
    if (this.totalMonthAmount5.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount5.loyalty.total)
    if (this.totalMonthAmount6.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount6.loyalty.total)
    if (this.totalMonthAmount7.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount7.loyalty.total)
    if (this.totalMonthAmount8.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount8.loyalty.total)
    if (this.totalMonthAmount9.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount9.loyalty.total)
    if (this.totalMonthAmount10.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount10.loyalty.total)
    if (this.totalMonthAmount11.loyalty.total)
      this.series[2].data.push(this.totalMonthAmount11.loyalty.total)

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    notFullMonth() {
      return 1
    },
  },
}
</script>
